ul {
  list-style-type: none;
  padding: 0;
}

li {
  border: 3px dashed mediumslateblue;
  width: 80vw;
  white-space: pre-line;
  margin-bottom: 1vh;
}

.date {
  font-size: medium;
  text-align: left;
  padding: 1vw;
}

.postForm {
  margin-top: 10vh;
  display: flex;
  flex-direction: row;
}

.postInput {
  width: 65vw;
  height: 10vw;
  border: 3px solid mediumslateblue
}

.postButton {
  margin-left: 30px;
  margin-top: 2.5vw;
  margin-right: 0;
  margin-left: 5vw;
  width: 10vw;
  height: 5vw;
  border-radius: 15px;
  border: 3px solid green
}